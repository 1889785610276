<template>
  <div class="faq">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: 'faq'
}
</script>

<style scoped lang='scss'></style>
